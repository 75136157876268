<template>
  <AssetHealthOverviewStyled>
    <TalpaLoaderWrapper v-if="$apollo.loading" />
    <template v-else>
      <AssetHealthEngineOrganism :asset="asset" :showECUDetails="showECUDetails" />
      <AssetHealthTyreOrganism :asset="asset" />
    </template>
  </AssetHealthOverviewStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

import { TalpaLoaderWrapper } from '@common/components'
import AssetHealthEngineOrganism from '../../SharedPages/AssetHealth/AssetHealthOverview/AssetHealthEngineOrganism.vue'
import AssetHealthTyreOrganism from '../../SharedPages/AssetHealth/AssetHealthOverview/AssetHealthTyreOrganism.vue'

const AssetHealthOverviewStyled = styled('div')`
  display: grid;
  grid-template-rows: max-content max-content;
  grid-gap: 1rem;
`

export default {
  components: {
    AssetHealthOverviewStyled,
    AssetHealthEngineOrganism,
    AssetHealthTyreOrganism,
    TalpaLoaderWrapper,
  },
  data() {
    return {
      showECUDetails: true,
    }
  },
  props: {
    asset: {
      type: Object,
      required: true,
    },
  },
}
</script>
